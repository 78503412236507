site.toggle = {
	init: function (toggleElementName, openElementName, bodyClass, onOpen, onClose, options) {
		var $body = $('body'),
			$toggleElement = $(toggleElementName),
			$openElement = $(openElementName);
		onOpen = onOpen || undefined;
		onClose = onClose || undefined;
		options = Object.assign({}, site.toggle.getDefaultOptions, (options || {}));

		$toggleElement.attr('aria-haspopup', true);

		var openElementId = $openElement.attr('id');
		if (!openElementId) {
			openElementId = toggleElementName + 'openElement';
			$openElement.attr('id', openElementId);
		}
		$toggleElement.attr('aria-controls', openElementId);

		$toggleElement.on('click', function (e) {
			e.preventDefault();
			e.stopPropagation();

			$body.toggleClass(bodyClass + '-open');

			if ($body.hasClass(bodyClass + '-open')) {
				$toggleElement.attr('aria-expanded', true);

				if (typeof onOpen === 'function') {
					onOpen();
				}

				$body.addClass(bodyClass + '-opening');
				setTimeout(function () {
					$body.removeClass(bodyClass + '-opening');
				}, 400);
			}
			else {
				close();
			}
		});

		$openElement.on('click', function (e) {
			let shouldStopPropagation = true;
			const exceptionClasses = ['fancybox'];
			if (e.target) {
				const $target = $(e.target);
				for (let i = 0; i < exceptionClasses.length; i++) {
					const exceptionClassSelector = '.' + exceptionClasses[i];
					if ($target.is(exceptionClassSelector) || $target.parents(exceptionClassSelector).length) {
						shouldStopPropagation = false;
					}
				}
			}
			if (shouldStopPropagation) {
				e.stopPropagation();
			}
		});

		if (options.closeOnBodyClick) {
			$body.on('click', function () {
				if ($('body').hasClass(bodyClass + '-open')) {
					close();
				}
			});
		}

		if (options.closeOnEscClick) {
			$(document).keydown(function (e) {
				if (e.which === 27 && $body.hasClass(bodyClass + '-open')) {
					close();
				}
			});
		}

		function close() {
			var $body = $('body');

			$body.removeClass(bodyClass + '-open');
			$toggleElement.attr('aria-expanded', false);

			if (typeof onClose === 'function') {
				onClose();
			}

			$body.addClass(bodyClass + '-closing');
			setTimeout(function () {
				$body.removeClass(bodyClass + '-closing');
			}, 400);
		}
	},

	getDefaultOptions: {
		closeOnBodyClick: true,
		closeOnEscClick: true,
	}
};
