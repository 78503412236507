site.accessibility = {
	init:function() {
		$(document).keydown(function (e) {
			if (e.which == 9) {
				$('body').addClass('tabbing');
			}
		});
		$(document).mousedown(function (e) {
			$('body').removeClass('tabbing');
		});
	}
};

site.inits.push('site.accessibility.init()');
