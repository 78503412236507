site.stillingar = {
	ms_cookie: null,

	init:function() {
		$('.stillingaris a[href="#"]').attr('onclick', 'return site.stillingar.ms_toggle();');
		site.stillingar.ms_init();
	},

	ms_init:function() {
		// read cookies and load settings
		ms_cookie = site.stillingar.ms_readCookie("style");
		if (ms_cookie == 'stillingar' && !$('body').hasClass('stillingar')) {
			site.stillingar.ms_append();
			$('body').addClass('stillingar');
		}
	},

	ms_toggle:function() {
		// toggle between settings
		if (site.stillingar.ms_getActive() != "stillingar")
		{
			site.stillingar.ms_append();
			site.stillingar.ms_createCookie("style", "stillingar", 0);
			$('body').addClass('stillingar');
		}
		else
		{
			site.stillingar.ms_disable();
			site.stillingar.ms_createCookie("style", "", 0);
			$('body').removeClass('stillingar');
			document.location = document.location.href;
		}
		return false;
	},

	ms_append:function() {
		// add link elements to stylesheets
		if ($('link[title=stillingar]').length < 1) {
			$('head').append(site.stillingar.ms_getStillingar());
		}
	},

	ms_disable:function() {
		$('link[title=stillingar]').remove();
	},

	ms_getActive:function() {
		return ($('body').hasClass('stillingar')) ? 'stillingar' : '';
	},

	ms_getStillingar:function() {
		var stylesheet = document.createElement("link");
		stylesheet.rel = "stylesheet";
		stylesheet.title = "stillingar";
		stylesheet.href = "http://minar.stillingar.is/lesa/stilar/css/";
		stylesheet.type = "text/css";
		stylesheet.media = "screen";
		return stylesheet;
	},

	ms_createCookie:function(name,value,days) {
		if (days) {
			var date = new Date();
			date.setTime(date.getTime()+(days*24*60*60*1000));
			var expires = "; expires="+date.toGMTString();
		}
		else {
			expires = "";
		}
		if (days!=0) {
			document.cookie = name+"="+value+expires+"; path=/";
		}
		else {
			document.cookie = name+"="+value+"; path=/";
		}
	},

 	ms_readCookie:function(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) {
				return c.substring(nameEQ.length,c.length);
			}
		}
		return null;
	}

};

site.inits.push('site.stillingar.init()');
