site.meetingBoxes = {
	init:function() {
		$('.boxTitle', '#meetingBoxes').on('click', function(e) {
			e.preventDefault();
			var $this = $(this),
				$box = $this.parent('.box'),
				open = $box.hasClass('open');

			$('.box', '#meetingBoxes').removeClass('open');

			if (!open) {
				$(this).parent('.box').toggleClass('open');
			}

			$('body').addClass('onemeeting-open');
			$('body.onemeeting-open').on('click', function (e) {
				if (!$(e.target).parents('#meetingBoxes').length) {
					$('body').removeClass('onemeeting-open');
					$('.box', '#meetingBoxes').removeClass('open');
				}
			});
		});
	}
};

site.inits.push('site.meetingBoxes.init()');
