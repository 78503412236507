site.weatherRoadDropdowns = {
	init: function() {
		this.bindParentDropdowns();
		this.bindChildrenDropdowns();
	},

	bindParentDropdowns: function() {
		var $body = $('body');
		const dropdowns = ['weather', 'road'];
		for (let i = 0; i < dropdowns.length; i++) {
			site.toggle.init(`#toggle-dropdown-${dropdowns[i]}`, `#dropdown-${dropdowns[i]}`, dropdowns[i], undefined, undefined, {
				closeOnBodyClick: false,
				closeOnEscClick: true
			});
			$('body, .dropdown-close').on('click', function(e) {
				if ($body.is(`.${dropdowns[i]}-open`)) {
					var container = $(`#dropdown-${dropdowns[i]}`);
					if ((!container.is(e.target) && container.has(e.target).length === 0) || $(e.target).is(`.${dropdowns[i]}-close`)) {
						if (!$(e.target).is('.fancybox-close')) {
							$body.removeClass(`${dropdowns[i]}-open`);
							$(`#toggle-dropdown-${dropdowns[i]}`).attr('aria-expanded', false);
						}
					}
				}
			});
		}
		$('.dropdownToggle').on('click', function() {
			let dropdownsToBeClosed = dropdowns.filter(selector => {
				return $(this).attr('id') !== `toggle-dropdown-${selector}`;
			});
			for (let i = 0; i < dropdownsToBeClosed.length; i++) {
				$body.removeClass(`${dropdownsToBeClosed[i]}-open`);
			}
		});
	},

	bindChildrenDropdowns: function() {
		const $dropdownChildTogglers = $('.dropdownChildToggler'),
			$dropdownTargets = $('.dropdownChild'),
			types = ['forecast', 'traffic'];

		if (!$dropdownChildTogglers.length) return false;

		$dropdownChildTogglers.each(function() {
			const type = $(this).data('type'),
				key = $(this).data('key'),
				$target = $dropdownTargets.filter(function() {
					return $(this).data('type') === type && $(this).data('key') === key;
				});
			$(this).on('click', function() {
				$dropdownTargets.removeClass('open');
				for (let i = 0; i < types.length; i++) {
					$('body').removeClass(`${types[i]}-open`);
				}
				$target.addClass('open');
				$dropdownChildTogglers.parents('tbody').find('tr').removeClass('open');
				$(this).parents('tr').addClass('open');
				$('body').addClass(`${type}-open`);
				$dropdownChildTogglers.attr('aria-expanded', false);
				$(this).attr('aria-expanded', true);
			});
		});

		// Delegate table row clicks to expand button handle
		$('.dropdownTable tr').on('click', function(e) {
			$(this).find('.dropdownChildToggler').triggerHandler('click');
		});
	}
};

site.inits.push('site.weatherRoadDropdowns.init()');

