Helpful = window.Helpful || {};
Helpful.init = function(options) {
	$('button#cancel').click(function() {
		window.history.back();
	});

	$('#helpful-1').click(function(e) {
		Helpful.helpfulPost();
		$('#fieldset_noFieldset').hide();
	});
	$('#helpful-0').click(function(e) {
		$('#fieldset_noFieldset').slideDown();
		$(this).parent().addClass('selected');
		$('#fieldset_yesFieldset').addClass('open');
		$('body').addClass('helpful-open');
	});
	$('#helpful_submit').click(function(e) {
		Helpful.helpfulPost();
	});
	$('#helpful_cancel').click(function(e) {
		$('#fieldset_noFieldset').slideUp();
		$('#helpful-0').parent().removeClass('selected');
		Helpful.helpfulReset();
	});
	$('#elm_helpful label').hover(function() {
		$(this).toggleClass('hover');
	});
	$('#fieldset_noFieldset #elm_reason .radio').click(function() {
		$('#fieldset_noFieldset #extra').focus();
		$('#fieldset_noFieldset #elm_extra label').text(options.language.explain);
	});

	$(document).mouseup(function (e) {
		var container = $("div.helpful");
		if (!container.is(e.target) && container.has(e.target).length === 0) {
			$('#fieldset_noFieldset').slideUp();
			$('#helpful-0').parent().removeClass('selected')
			Helpful.helpfulReset();
		}
	});
};

Helpful.helpfulPost = function() {
	$.post('/_/' + moya.division + '/helpful/index/index', $('#helpful_form').serialize(), function (result) {
		if(!result.success) {
			if(result.message) {
				var errorHtml = '<ul class="errors"><li>' + result.message.message + '</li></ul>';
				$('#elm_' + result.message.field).append(errorHtml);
			}
		}
		else {
			$('#helpful-success').fadeIn();
			$('#helpful_form').toggleClass('hide');
			$('#center .helpful').addClass('success');
			Helpful.helpfulReset();
		}
	}, 'json');
};

Helpful.helpfulReset = function() {
	$('#helpful-0, #helpful-1').prop('checked', false);
	$('#fieldset_yesFieldset').removeClass('open');
	$('body').removeClass('helpful-open');
};
