site.megamenu = {
	hasExtras:['stjornsysla'],

	init:function() {
		var $mainmenu = $('#mainmenu');
		$('.mmLevel2', $mainmenu).wrap('<div class="megamenu"/>');
		$mainmenu.on('click', function(e) {
			e.stopPropagation();
		});

		site.megamenu.background();
		site.megamenu.loadLinks();

		$('.level1.children > a',$mainmenu).on('click', function (e) {
			if ($(document).width()+15 >= 992 && !$('body').hasClass('stillingar')) {
				e.preventDefault();
				e.stopPropagation();

				//Click on node that is already open
				if ($(this).parent().hasClass('open')) {
					site.megamenu.close();
				}
				//Open megamenu
				else {
					$('body').addClass('megamenu-open');
					$('.level1.open', $mainmenu).removeClass('open');
					$('.megamenu', $mainmenu).removeAttr('style');
					$(this).parent().addClass('open');
					var $megamenu = $(this).parent().find('.megamenu'),
						$megamenuHeight = $megamenu[0].scrollHeight + 5;
					$megamenu.css('max-height', $megamenuHeight);
					$('#megamenuBg').css('height', $megamenuHeight);
					$('#pageContainer').css('min-height', $megamenuHeight+142);
				}

				$('body.megamenu-open').on('click', function (e) {
					site.megamenu.close();
				});
			}
		});
	},

	close:function() {
		var $body = $('body'),
			$mainmenu = $('#mainmenu');
		$('.megamenu', $mainmenu).removeAttr('style');
		$('#megamenuBg').removeAttr('style');
		$('.level1.open', $mainmenu).removeClass('open');
		$('#pageContainer').removeAttr('style');

		if ($body.hasClass('megamenu-open')) {
			$body.addClass('megamenu-closing');
			setTimeout(function() {
				$body.removeClass('megamenu-closing');
			},400);
		}
		$('body').removeClass('megamenu-open');
	},

	background:function() {
		var $mainmenu = $('#mainmenu'),
			$header = $('#header');
		$header.append('<div id="megamenuBg" />');
		$(window).resize(function() {
			if ($('body').hasClass('megamenu-open')) {
				var $megamenu = $('.open .megamenu', $mainmenu),
					$megamenuHeight = $megamenu[0].scrollHeight;
				$megamenu.css('max-height', $megamenuHeight);
				$('#megamenuBg').css('height', $megamenuHeight);
				$('#pageContainer').css('min-height', $megamenuHeight+142);
			}
		});
	},

	loadLinks:function() {
		$('.megamenu', '#mainmenu').each(function() {
			var $megamenu = $(this),
				$li = $megamenu.parents('.level1'),
				$a = $li.find("> a:not(.expand)"),
				href = $a.attr('href'),
				codename = (href) ? href.replace('/is/', '') : '';

			if (site.megamenu.hasExtras.indexOf(codename) >= 0) {
				$.ajax({
					url: '/moya/extras/megamenu-'+codename,
					type: 'get'
				}).done(function(r) {
					if (r.length) {
						$megamenu.append(r);
					}
				});
			}
		});
	}
};
