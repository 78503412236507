var site = site || {};
site.inits = [];

site.window = {
	scrollPosition: 0
};

site.device = {
	// Check if device is a touch device
	isTouch: function() {
		try {
			document.createEvent("TouchEvent");
			return true;
		} catch (e) {
			return false;
		}
	}
};


// A few layout methods
site.layout = {
	// Wrap elements into a div.row
	rowWrap: function(selectorBegin, selectorEnd, extraClasses) {
		if (selectorEnd == undefined) {
			selectorEnd = selectorBegin;
		}

		if (extraClasses == undefined) {
			extraClasses = '';
		}

		$(selectorBegin).nextUntil(selectorEnd).andSelf().add(selectorEnd)
			.wrapAll("<div class='row " +extraClasses+ "'></div>");
	}
}


site.owl = {
	// Shorthand to init owlcarousel
	init: function(id, options) {
		var $owl = $(id);
		if ($owl.length > 0) {
			$owl.addClass('owl-carousel'); // This is to fix a change made in owl 2.0.0-beta.3
			$owl.owlCarousel(options);
		}
		return $owl;
	}
};

site.dropdown = {
	init:function() {
		var $body = $('body');
		if (!$body.hasClass('frontpage')) return false;

		site.dropdown.setHeight();
		$(window).resize(function() {
			site.dropdown.setHeight();
		});

		$('.content','.dropdown').on('click', function(e) {
			e.stopPropagation();
		});
		$('.title', '.dropdown').on('click', function(e) {
			e.preventDefault();
			e.stopPropagation();
			var $parent = $(this).parent();
			if (!$parent.hasClass('open')) {
				$('.category','.dropdown').removeClass('open');
				$parent.addClass('open');
				$body.addClass('dropdown-open');
			}
			else {
				site.dropdown.close();
			}

			$('body.dropdown-open').on('click', function () {
				site.dropdown.close();
			});
		});
	},

	setHeight:function() {
		var $dropdown = $('.dropdown');
		$('ul', $dropdown).show();
		$('.content', $dropdown).each(function() {
			$(this).css('max-height', $(this)[0].scrollHeight);
		});
		$('ul', $dropdown).removeAttr('style');
	},

	close:function() {
		$('.category','.dropdown').removeClass('open');
		setTimeout(function() {
			$('body').removeClass('dropdown-open');
		},400);
	}
};

site.frontpage = {
	carousel:null,

	init: function () {
		var $body = $('body');
		if (!$body.hasClass('frontpage')) return false;

		site.frontpage.banner();
		site.frontpage.icon();

		$(window).resize(function() {
			site.frontpage.banner();
		});
	},

	icon: function() {
		$('.icon.svg').each(function() {
			var icon = $(this).data('icon');
			if (icon && icon.length) {
				var style = "url('/static/files/icons/" + icon + ".svg') no-repeat center / contain;";
				$(this).attr('style', "-webkit-mask: " + style + "mask: " + style);
			}
		});
	},

	banner: function() {
		if (site.frontpage.carousel) {
			site.frontpage.carousel.owlCarousel('destroy')
		}

		if ($('body').width() + 15 >= 1200) {
			var width = $('#box_group_1b').outerWidth(true);
			var height = $('#box_group_1a').outerHeight(true);
			$('.item', '#owl-hero').css('width', width).css('height', height);
		}
		else {
			$('.item', '#owl-hero').removeAttr('style');
		}

		if ($('#owl-hero .item').length > 1) {
			site.frontpage.carousel = site.owl.init('#owl-hero .items', {
				loop: true,
				dots: true,
				nav: false,
				items: 1,
				navText: ["", ""],
				stopOnHover: true,
				autoplay: true,
				autoplayTimeout: 8000,
				autoplayHoverPause: true,
				animateOut: 'fadeOut',
				animateIn: 'fadeIn',
			});
		}

	}
};

// Run all the inits \o/
$(function() {
	$.each(site.inits, function(i, value) {
		var fun = new Function(value);
		fun();
	});

	site.megamenu.init();
	site.dropdown.init();
	site.frontpage.init();

	//Responsive youtube and vimeo iframe
	$('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]', '#main').each(function() {
		$(this).attr('allowfullscreen','').wrap('<div class="video" style="max-width:'+$(this).width()+'px"><div class="embed-responsive embed-responsive-16by9"></div></div>');
	});

	// Responsive tables
	if (!$('body').is('.admin')) {
		$('table', '#main').wrap('<div class="table-responsive"></div>');
	}

	// Touch login
	$('.login').dblclick(function(e) {
		e.preventDefault();
		window.location = '/moya';
	});

	// Reorder language menu based on selected
	// Move is to the top
	//$li = $('.selected', '#divisions');
	//$ul = $('.menu', '#divisions');
	//$ul.prepend($li);
});
