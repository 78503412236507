site.headSearch = {
	init: function () {
		var $body = $('body'),
			$s = $('#searchBox');

		$s.on('click touchstart', function(e) {
			e.stopPropagation();
		});

		$('.searchToggle').on('click touchstart', function (e) {
			e.preventDefault();
			e.stopPropagation();

			site.megamenu.close();

			$body.toggleClass('search-open');

			$('body.search-open').on('click touchstart', function () {
				site.headSearch.close(true);
			});

			if ($body.hasClass('search-open')) {
				$('#q').focus();
				$('i', '.searchToggle').removeClass('fa-search').addClass('fa-close');
			}
			else {
				site.headSearch.close(true);
			}
		});
	},

	close : function(closing) {
		closing = closing || false;
		var $body = $('body');

		if (!closing && !$body.hasClass('search-open')) return;

		$body.removeClass('search-open');
		$('i', '.searchToggle').removeClass('fa-close').addClass('fa-search');

		if (closing) {
			$body.addClass('search-closing')
			setTimeout(function() {
				$('body').removeClass('search-closing');
			},400);
		}
	}
};

site.inits.push('site.headSearch.init()');
