site.mMenu = {
	init: function() {
		var $body = $('body'),
			$html = $('<div id="mMenu" role="navigation"></div>'),
			$mMenu = $('#mainmenu').find('.mmLevel1').clone().removeAttr('id');
		$('ul',$mMenu).removeAttr('id');
		$html.append($mMenu);
		$body.append($html);

		var $navbar = '<a href="#mm-1" class="home">Til baka</a>';

		$('#mMenu').mmenu({
			// options
			offCanvas: {
				position: "right"
			},
			extensions: ["theme-moya"],
			navbars: [{
				position: "top",
				content: $navbar,
			}, {
				position: "top",
				content: '',
			}]
		},{
			// configuration
			classNames: {
				selected:"active"
			}
		});

		var API = $("#mMenu").data( "mmenu" );

		$("#mainmenuToggle").click(function() {
			API.close();
		});


		$('.mm-page.mm-slideout').removeClass('mm-slideout');

		$('#mMenu .mm-next').append('<span class="sr-only">Next</span>');
		$('#mMenu .mm-btn.mm-prev').append('<span class="sr-only">Prev</span>');
		$('#mSearchForm label').remove();

		site.mMenu.appendQn();
		site.mMenu.appendSearch();
	},

	appendQn: function() {
		var $menu = $('.mm-navbar', '#mm-1'),
			$qn = $('.qn').clone();
		if(window.screen.width > 992) {
			$('ul:first-child li:first-child', $qn).remove();
		}
		$qn.appendTo($menu);
	},

	appendSearch: function() {
		var $html = $('<div id="mSearchBox" role="search"></div>'),
			$mSearchBox = $('#searchForm').clone().removeAttr('id'),
			$menu = $('.mm-navbar', '#mm-1');
		$html.append($mSearchBox);
		$html.appendTo($menu);

		$('[id]', $mSearchBox).each(function() {
			var $this = $(this),
				$id = $this.attr('id'),
				$newId = 'm'+$id.substr(0,1).toUpperCase()+$id.substr(1),
				$label = $('label[for="'+$id+'"]', $mSearchBox);

			if ($label.length > 0) {
				$label.attr('for', $newId);
			}
			$this.attr('id', $newId);

			if (typeof $this.attr('name') !== typeof undefined) {
				$this.attr('name', $newId);
			}
		});
	},
};

site.inits.push('site.mMenu.init()');
