var site = site || {};
site.extras = site.extras || {};
site.extras.initDone = false;
/*
 Extras
 */
site.extras.upload =  {
	init: function(urls, lang) {
		if(site.extras.initDone === false) {
			site.extras.initDone = true;
			$('ul#images').sortable({
				'opacity': 0.8,
				'update': function (e, ui) {
					var $el = $(this);
					$el.sortable('disable').addClass('disabled');
					$.post(urls.sort, $(this).sortable('serialize'), function (d) {
						if (d.success !== true) {
							moya.message.add(lang.galleryErrorOnSave);
							$el.sortable('cancel');
						}
						$el.removeClass('disabled');
						$el.sortable('enable');
					});
				}
			});
			$('#ffile').uploadify({
				'uploader': '/_moya/js/jquery/uploadify/uploadify.swf',
				'script': urls.script,
				'cancelImg': '/_moya/js/jquery/uploadify/cancel.png',
				'auto': true,
				'multi': true,
				'folder': '/tmp',
				'fileDesc': lang.galleryImageUploadFileDescription,
				'fileExt': '*.jpg;*.jpeg;*.gif;*.png;*.JPG;*.JPEG;*.GIF;*.PNG,*.pdf;*.PDF;*.doc;*.docx;*',
				'buttonText': lang.galleryFileBrowse,
				'queueID': 'ffileQueue',
				'onError': function () {
					$('#notice').html('<div class="message"><div class="error">' + lang.galleryErrorOnSave + '</div></div>');
				},
				'onComplete': function (e, q, f, r) {
					var n = $('#notice');
					switch (r) {
						case 'noAlbum':
							n.html('<div class="message"><div class="error">' + lang.galleryErrorNoAlbumName + '</div></div>');
							break;
						case 'noSave':
							n.html('<div class="message"><div class="error">' + lang.galleryErrorOnSave + '</div></div>');
							break;
					}
				},
				'onAllComplete': function () {
					window.location.reload();
				}
			});
		}
	}
};

site.extras = $.extend(site.extras, {
	itemForm: function() {
		$('select#urlSitemap').bind('change click keyup', function() {
			$('input#url').val($(this).val());
		});
	},
	categoriesSelect:function() {
		$('select#categoryId').bind('change', function() {
			if ($(this).val() != 'selectionKey') {
				window.location = '?categoryId=' + $(this).val();
			}
		});
	}
});
