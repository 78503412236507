var emplist = (function ($) {
	"use strict";
	var $emplist,
		init = function () {
			$emplist = $('#emplist');
			if ($emplist.length > 0) {
				if ($emplist.hasClass('mainList')) {
					emplist.main.init();
				}
			}
		};
	$(document).ready(init);
	return {
		$: $,
		g: function () {
			return $emplist;
		}
	};
})(jQuery);

emplist.main = (function (parent) {
	"use strict";
	var $ = parent.$,
		$form,
		$input,
		$select,
		$alpha,
		$list,
		$infos,
		init = function () {
			$list = parent.g().find('ul.toolbar');
			$infos = parent.g().find('div.content');
			jQuery.expr[':'].startsWith = function (a, i, m) {
				//noinspection JSConstructorReturnsPrimitive
				var t = $(a).text().replace(/^\s*/, '').substring(0, 1);
				return (t || "").toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
			};
			jQuery.expr[':'].containsIC = function (a, i, m) {
				var t = $(a).text();
				return (t || "").toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
			};
			initSearch();
			//initInfo();
		},
		initSearch = function () {
			$form = parent.g().find('form');
			$form.on('submit', function(e) {
				e.preventDefault();
			});
			$input = $form.find('input').keyup(function (e) {
				//if (e.which == 13) { // Enter
					var t = this;
					doSearch.call(t);
				//}
			});
			$select = $form.find('select').change(doSearch);
			$alpha = $form.find('a').click(doSearch);
		},
		initInfo = function () {
			// Note the .internal = used to turn this feature on
			$list.find('td.name.internal').find('a').click(function(e){
				e.preventDefault();
				var $this = $(this);
				var $tr = $this.parents('tr');
				var $next = $tr.next();
				var isHidden = $next.hasClass('hide');
				$infos.addClass('hide');
				if (isHidden) {
					$next.removeClass('hide');
					showImg($next.find('img'));
				}

				$tr.toggleClass('showInfo');
			});
		},
		showImg = function($img) {
			var src = $img.attr('src');
			if (!src) {
				$img.attr('src', $img.data('src'));
			}
		},
		doSearch = function (e) {
			if (e) {
				e.preventDefault();
			}
			var $this = $(this);
			var t = this.tagName;
			if (t == 'SELECT') {
			}
			else if (t == 'INPUT') {
			}
			else {
				// t = a
				$alpha.removeClass('selected');
				$this.addClass('selected');
			}
			var searchValue = $input.val();
			var selectValue = $select.val();
			var alphaHref = $alpha.filter('.selected').attr('href');
			var selectedAlpha = (alphaHref) ? alphaHref.replace('#', '').substring(0, 1) : '';
			var $res = $list;
			if (selectValue) {
				$res = $res.filter('.' + selectValue);
			}
			if (selectedAlpha) {
				$res = $res.find('li.name:startsWith("' + selectedAlpha + '")').parent();
			}
			if (searchValue) {
				$res = $res.find('li:containsIC("' + searchValue + '")').parent();
			}
			$list.removeClass('open');
			result($res);
		},
		result = function ($filtered) {
			if ($filtered) {
				$list.hide();
				$filtered.show();
			}
			else {
				$list.show();
			}
			if ($filtered.length < $list.length) {
				$('#emplist').addClass('filtered');
			}
			else {
				$('#emplist').removeClass('filtered');
			}
		};
	return {
		init: init
	};
})(emplist);
