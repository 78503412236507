site.adverts = {
	init: function() {
		if($('#region_id').val() != 11) {
			$('[id^=color]').addClass('hide');
		}
		$('#region_id').change(function() {
			if($(this).val() != 11) {
				$('[id^=color]').addClass('hide');
			}
			else {
				$('[id^=color]').removeClass('hide');
			}
		});
	}
};