Helpful = window.Helpful || {};

site.helpful = {
	init:function() {
		$('#abendingar #fieldset_noFieldset input[type=radio]').each(function() {
			$(this).parent().before(this);
		});
		$('#helpful-0').click(function(e) {
			if ($('body').width() > 992-15) {
				var scrollPosition = $(window).scrollTop() + 287;
				$("html, body").animate({scrollTop: scrollPosition}, 600);
			}
		});

		$('.close', '.helpful').on('click', function(e) {
			e.preventDefault();
			$('#fieldset_noFieldset').slideUp();
			$('#helpful-0').parent().removeClass('selected')
			Helpful.helpfulReset();
		});
	}
};

site.inits.push('site.helpful.init()');
