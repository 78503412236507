site.accordion = site.accordion || {};
site.accordion = $.extend({
	init: function () {
		var $body = $('body'),
			$accordions = $('section.accordion');
		$accordions.each(function () {
			var $this = $(this);
			$('>*:not(:first-child)', $this).wrapAll('<div class="wrap" />');
			$('>:first-child', $this).contents().eq(0).wrap('<a href="#">');

		});

		site.accordion.addHashes();
		$(window).load(function() {
			setTimeout(function() {
				$accordions.addClass('ready');
			}, 1);
			site.accordion.initMaxHeight();
			site.accordion.hashOpen();
			site.accordion.followHash();
		});

		$body.on('click', 'section.accordion >:first-child > a:first-child', function(e) {
			var $accordion = $(this).closest('.accordion');
			e.preventDefault();
			e.stopPropagation();
			$accordion.toggleClass('open');

			if ($accordion.hasClass('open')) {
				site.accordion.checkForSubSections($accordion);
				site.accordion.addTimeoutClass($accordion, 'opening');
				site.accordion.updateHash($(this).attr('href'));
			}
			else {
				site.accordion.addTimeoutClass($accordion, 'closing');
				site.accordion.updateHash('');
			}
		});

		$body.on('click', 'section.accordion', function(e) {
			var $this = $(this);
			if (!$this.hasClass('open')) {
				e.preventDefault();
				e.stopPropagation();
			}

			if ($this.attr('class').indexOf('open') === -1 && $(e.target).prop('tagName') !== 'A') {
				$this.find('>:first-child a').trigger('click');
			}
		});
	},

	addTimeoutClass: function (element, className, time) {
		time = (time === undefined) ? 400 : time;
		element.addClass(className);
		setTimeout(function () {
			element.removeClass(className);
		}, time);
	},

	initMaxHeight: function () {
		if ($('section.accordion .wrap').length === 0) return;
		$(window).load(function() {
			site.accordion.setHeight();
		});
		$(window).resize(function () {
			site.accordion.setHeight();
		});
		$(window).trigger('resize');
	},

	setHeight: function ($elm) {
		var $accordion = $elm !== undefined ? $elm : $('section.accordion');
		var max = 0;
		$accordion.each(function() {
			var $this = $(this),
				isOpen = $this.hasClass('open');

			$this.addClass('closing');
			$('> .wrap', $this).each(function () {
				var height = $(this)[0].scrollHeight;
				$(this).css('max-height', height);
				if (height > max) {
					max = height;
				}
			});

			if (!isOpen) {
				$this.removeClass('closing');
			}
		});
		return max;
	},

	getOpenOffset: function() {
		return 30;
	},

	getLoggedInOffset: function() {
		return $('body').hasClass('loggedIn') ? 31 : 0;
	},

	shouldAddHashes: false,
	addHashes: function() {
		if (!site.accordion.shouldAddHashes) {
			return;
		}

		var $accordion = $('section.accordion');
		$accordion.each(function () {
			var $this = $(this);
			var title = $('>:first-child a', $this).text()
			var anchor = site.accordion.getAnchorFromTitle(title);
			$this.attr('id', 'accordion-' + anchor);
			$('>:first-child a', $this).attr('href', '#' + anchor);
		});
	},

	getAnchorFromTitle: function(title) {
		let anchor = title.trim().toLowerCase(),
			special = {
				á:'a',
				ð:'d',
				é:'e',
				í:'i',
				ó:'o',
				ú:'u',
				ý:'y',
				þ:'th',
				æ:'ae',
				ö:'o'
			},
			r;
		for(var i in special){
			r = new RegExp(i,"g");
			anchor = anchor.replace(r,special[i]);
		}
		return anchor.replace(/[^a-zA-Z0-9\s]/g,"").replace(/\s+/g, '-');
	},

	shouldUpdateHash: false,
	updateHash: function(hash) {
		if (!site.accordion.shouldUpdateHash) {
			return;
		}

		let scrollTop = $(window).scrollTop() ;
		location.hash = hash;
		window.scrollTo(0, scrollTop);
	},

	shouldFollowHash: false,
	followHash: function() {
		if (!site.accordion.shouldFollowHash || $('section.accordion').length === 0) {
			return;
		}

		$('body').on('click', 'a[href*=#]:not([href=#])', function(e) {
			if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'')
				&& location.hostname === this.hostname
				&& !$(this).parents('section.accordion > :first-child').length) {
				let $target = $(this.hash);
				$target = $target.length ? $target : $(this.hash.replace('#', '#accordion-'));
				if ($target.length) {
					$target.trigger('click');
					$('body,html').animate({
						scrollTop: $target.offset().top - site.accordion.getOpenOffset() - site.accordion.getLoggedInOffset()
					});
				}
			}
		});
	},

	hashOpen: function() {
		var $accordion = $('section.accordion');
		if ($accordion.length > 0 && location.hash.length > 0 && !$('body').hasClass('admin')) {
			var $open = $accordion.filter(location.hash);
			if (!$open.length) {
				$open = $accordion.filter(location.hash.replace('#', '#accordion-'));
			}
			if ($open.length) {
				$open.addClass('open');

				$('body,html').animate({
					scrollTop: $open.offset().top - site.accordion.getOpenOffset() - site.accordion.getLoggedInOffset()
				});
			}
		}
	},

	checkForSubSections: function($parent) {
		var $subSections = $('section.accordion', $parent);
		if ($subSections.length === 0) {
			if ($parent.closest('section.accordion').length > 0) {
				$parent = $parent.parents('section.accordion');
				$subSections = $('section.accordion', $parent);
			}
		}

		if ($subSections.length > 0) {
			var max = site.accordion.setHeight($subSections);
			var $wrap = $('>.wrap', $parent);
			var currentHeight = $wrap[0].scrollHeight;
			$parent.removeAttr('style');
			setTimeout(function () {
				$wrap.css('max-height', currentHeight + max);
			}, 400);

		}
	},

	toggleAll: function(selector, parentSelector) {
		parentSelector = parentSelector || '#main';
		$(selector).on('click', function() {
			var $this = $(this),
				isOpen = $this.hasClass('open');

			$('.accordion > p:first-child a', parentSelector).each(function() {
				var $openLink = $(this),
					$section = $openLink.closest('section');
				if ((!isOpen && !$section.is('.open')) || (isOpen && $section.is('.open'))) {
					$openLink.trigger('click');
				}
			});

			if (isOpen && $this.data('expand')) {
				$this.text($this.data('expand'));
			}
			else if (!isOpen && $this.data('collapse')) {
				$this.text($this.data('collapse'));
			}

			$this.toggleClass('open');
		});
	}
}, site.accordion);

site.inits.push('site.accordion.init()');
